.app-header {
  margin-bottom: 2rem;
  font-family: 'Open Sans', 'Lato', sans-serif;
}
.app-header > .logo {
  height: 2.5rem;
}
.app-header > .MHub-Header {
  margin: 0.25rem 0;
  text-align: center;
  font-family: 'Open Sans', 'Lato', sans-serif;
}
.app-header p {
  color: rgba(51, 51, 51, 1);
}

.app-header > .logo_2019 > img {
  width: 12rem;
}
