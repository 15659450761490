.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@keyframes move {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.0);
  }
}

/* .App:not(.new_year_2019):not(.cny):before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('../../images/backgrounds/pattern.png');
  background-repeat: repeat;
  background-size: cover;
  user-select: none;
  pointer-events: none;
} */

.App .MHub-Container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  transition: all 0.25s;
  max-height: 100vh;
}

.App .MHub-Container .MHub-Form {
  flex: 1 1 auto;
}

@media only screen and (max-width: 480px) and (max-height: 488px) {
  .App {
    background: white;
  }
}

@media only screen and (max-height: 488px) {
  .App .MHub-Container {
    top: 0;
    transform: translateX(-50%);
    height: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .App .MHub-Container {
    width: 100%;
  }
}

.MHub-Container:not(.basic) {
  box-shadow: 0.15rem 0.15rem 0.35rem 0 rgb(0 0 0 / 15%);
}

.background {
  animation: move 70s ease-in-out infinite;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  transition: background-image 0.3s, background-position-x 0.3s;
}

/* BACKGROUND FADE IN ANIMATION */
/* .background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 1;
  background-color: white;
  transition: opacity 0.5s ease-in-out;
}
body.loaded .background::before {
  opacity: 0;
} */


/* ----------------
====== MOBILE ======
---------------- */
@media only screen and (max-width: 480px), only screen and (max-height: 480px) {
  /* .App:not(.new_year_2019):not(.cny):not(.hr) {
    background-position-x: right;
    background-image: url('../../images/backgrounds/default/bg_default_mobile.jpg');
  } */
  .background.bg_default {
    background-position-x: right;
    background-image: url('../../images/backgrounds/default/bg_default_mobile.jpg');
  }
  .background.new_year_2019 {
    background-image: url('../../images/backgrounds/new_year/bg_2019_mobile.jpg');
  }
  .background.cny {
    background-image: url('../../images/backgrounds/cny/bg2025_mobile_1.jpg');
  }
  .background.cny_2 {
    background-image: url('../../images/backgrounds/cny/bg2025_mobile_2.jpg');
  }
  .background.cny_3 {
    background-image: url('../../images/backgrounds/cny/bg2025_mobile_3.jpg');
  }
  .background.cny_4 {
    background-image: url('../../images/backgrounds/cny/bg2025_mobile_4.jpg');
  }
  .background.cny_5 {
    background-image: url('../../images/backgrounds/cny/bg2025_mobile_5.jpg');
  }
  .background.hr {
    background-image: url('../../images/backgrounds/hr/bg_hr_mobile.jpg');
  }
  .background.christmas {
    background-image: url('../../images/backgrounds/christmas/bg2024_mobile_1.jpg');
  }
  .background.christmas_2 {
    background-image: url('../../images/backgrounds/christmas/bg2024_mobile_2.jpg');
  }
  .background.christmas_3 {
    background-image: url('../../images/backgrounds/christmas/bg2024_mobile_3.jpg');
  }
  .background.christmas_4 {
    background-image: url('../../images/backgrounds/christmas/bg2024_mobile_4.jpg');
  }
  .background.harvest {
    background-image: url('../../images/backgrounds/harvest/bg_mobile.jpg');
  }
  .background.merdeka {
    background-image: url('../../images/backgrounds/merdeka/bg_2023_merdeka_mobile.jpg');
  }
  .background.bg_mon {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/01_Mon_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/01_Mon_1087_x720.jpg');
  }
  .background.bg_tue {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/02_Tue_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/02_Tue_1087_x720.jpg');
  }
  .background.bg_wed {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/03_Wed_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/03_Wed_1087_x720.jpg');
  }
  .background.bg_thu {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/04_Thu_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/04_Thu_1087_x720.jpg');
  }
  .background.bg_fri {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/05_Fri_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/05_Fri_1087_x720.jpg');
  }
  .background.bg_sat {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/06_Sat_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/06_Sat_1087_x720.jpg');
  }
  .background.bg_sun {
    /* background-image: url('../../images/backgrounds/merdeka/daily_new/07_Sun_1087_x720.jpg'); */
    background-image: url('../../images/backgrounds/default/daily/07_Sun_1087_x720.jpg');
  }
  .background {
    animation: none !important;
  }
}

/* ----------------
====== DESKTOP ======
---------------- */
.background.bg_default {
  background-image: url('../../images/backgrounds/default/bg_default_web.jpg');
}
.background.new_year_2019 {
  background-image: url('../../images/backgrounds/new_year/bg_2019_web.jpg');
}
.background.cny {
  background-image: url('../../images/backgrounds/cny/bg2025_desktop_1.jpg');
}
.background.cny_2 {
  background-image: url('../../images/backgrounds/cny/bg2025_desktop_2.jpg');
}
.background.cny_3 {
  background-image: url('../../images/backgrounds/cny/bg2025_desktop_3.jpg');
}
.background.cny_4 {
  background-image: url('../../images/backgrounds/cny/bg2025_desktop_4.jpg');
}
.background.cny_5 {
  background-image: url('../../images/backgrounds/cny/bg2025_desktop_5.jpg');
}
.background.hr {
  background-image: url('../../images/backgrounds/hr/bg_hr_web.jpg');
}
.background.christmas {
  background-image: url('../../images/backgrounds/christmas/bg2024_desktop_1.jpg');
}
.background.christmas_2 {
  background-image: url('../../images/backgrounds/christmas/bg2024_desktop_2.jpg');
}
.background.christmas_3 {
  background-image: url('../../images/backgrounds/christmas/bg2024_desktop_3.jpg');
}
.background.christmas_4 {
  background-image: url('../../images/backgrounds/christmas/bg2024_desktop_4.jpg');
}
.background.harvest {
  background-image: url('../../images/backgrounds/harvest/bg_desktop.jpg');
}
.background.merdeka {
  background-image: url('../../images/backgrounds/merdeka/bg_2023_merdeka_web.jpg');
}
/* daily bgs */
.background.bg_mon {
  background-image: url('../../images/backgrounds/default/daily/01_Mon_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/01_Mon_1920_x_1080.jpg'); */
}
.background.bg_tue {
  background-image: url('../../images/backgrounds/default/daily/02_Tue_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/02_Tue_1920_x_1080.jpg'); */
}
.background.bg_wed {
  background-image: url('../../images/backgrounds/default/daily/03_Wed_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/03_Wed_1920_x_1080.jpg'); */
}
.background.bg_thu {
  background-image: url('../../images/backgrounds/default/daily/04_Thu_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/04_Thu_1920_x_1080.jpg'); */
}
.background.bg_fri {
  background-image: url('../../images/backgrounds/default/daily/05_Fri_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/05_Fri_1920_x_1080.jpg'); */
}
.background.bg_sat {
  background-image: url('../../images/backgrounds/default/daily/06_Sat_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/06_Sat_1920_x_1080.jpg'); */
}
.background.bg_sun {
  background-image: url('../../images/backgrounds/default/daily/07_Sun_1920_x_1080.jpg');
  /* background-image: url('../../images/backgrounds/merdeka/daily_new/07_Sun_1920_x_1080.jpg'); */
}
