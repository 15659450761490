.route-wrapper {
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s, max-width 0s, max-height 0s, opacity 0s;
}

@media only screen and (max-height: 488px) {
  .route-wrapper {
    top: 0;
    transform: translateX(-50%);
    height: 100%;
  }
}
