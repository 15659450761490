.Redirect {
  width: 29rem;
}

.Redirect .app-header {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.Redirect .app-header > .logo {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 480px) {
  .Redirect .MHub-Column:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
