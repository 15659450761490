.Notice .MHub-Image.Notice-image {
  width: 100%;
}

.Notice .MHub-Image.Notice-image img {
  max-width: 89vw;
  max-height: 80vh;
}

.Notice .actions {
  margin-top: 1rem;
}

.Notice .frame-wrapper {
  display: block;
}

.Notice .frame-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.Notice .frame-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.Notice .column {
  width: 500px;
}


@media only screen and (max-height: 639px) {
  .Notice .frame-container {
    padding-top: 75vh;
  }
}

@media only screen and (min-height: 640px) {
  .Notice .frame-container {
    padding-top: 550px;
  }
}

@media only screen and (max-width: 500px) {
  .Notice .frame-container {
    padding-top: 110vw;
  }
}
